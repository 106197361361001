import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Event() {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      window.location.href = process.env.REACT_APP_EVENT_REDIRECT;
      return;
    }
  }, []);

  return <></>;
}

export default React.memo(Event);
